export default [
  {
    title: 'MENU_HOME',
    route: 'home',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'MENU_NEW_RECLAMATION',
    route: 'newReclamation',
    icon: 'FilePlusIcon',
    tagVariant: 'light-warning',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'MENU_MY_RECLAMATION',
    route: 'myReclamation',
    icon: 'FileTextIcon',
    tagVariant: 'light-warning',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'MENU_ALL_RECLAMATION',
    route: 'allReclamation',
    icon: 'LayersIcon',
    tagVariant: 'light-warning',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'MENU_STATS',
    route: 'statistiques',
    icon: 'PieChartIcon',
    tagVariant: 'light-warning',
    action: 'read',
    resource: 'ACL',
  },

  {
    header: 'MENU_BACKOFFICE',
  },
  {
    title: 'COMPANY',
    icon: 'UsersIcon',
    children: [
      {
        title: 'MENU_COMPANY_NEW',
        route: 'newCompany',
        icon: 'UserPlusIcon',
        tagVariant: 'light-warning',
      },
      {
        title: 'MENU_COMPANY_LIST',
        icon: 'ListIcon',
        route: 'companyListing',
      },
      {
        title: 'MENU_COMPANY_IMPORT',
        route: 'importCompany',
        icon: 'DownloadIcon',
        tagVariant: 'light-warning',
      },
    ],
  },
  {
    title: 'MENU_USER',
    icon: 'UserIcon',
    children: [
      {
        title: 'MENU_USER_LIST',
        icon: 'ListIcon',
        route: 'usersListing',
      },
      {
        title: 'MENU_NEW_USER',
        route: 'newUtilisateur',
        icon: 'UserPlusIcon',
        tagVariant: 'light-warning',
      },
    ],
  },
  {
    title: 'MENU_SETTINGS',
    route: 'parametrage',
    icon: 'SettingsIcon',
  },
  {
    title: 'MENU_TRANSLATE',
    route: 'traduction',
    icon: 'FlagIcon',
  },

]
